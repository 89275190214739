import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryRawStreets from "../components/galleryRawStreets"

const RawStreets = () => (
  <Layout>
    <SEO title="Documentary photography" />
    <h1>Documentary photography</h1>
    <p>Projects that are not finished yet, but would like to release in the next years.</p>
    <div class="gallery big">
    	<GalleryRawStreets />
    </div>
  </Layout>
)

export default RawStreets
